svg #_B {
    /* stroke: #000; */
    stroke-width: 1;
    stroke-dasharray: 0;
    animation: animateLetter 1s linear forwards;
    animation-delay: .5s;
  }

svg #_U {
    /* stroke: #e0e1e0; */
    stroke-width: 1;
    stroke-dasharray: 0;
    animation: animateLetter 1s linear forwards;
    animation-delay: .5s;
  }

svg #_S {
    /* stroke: #e0e1e0; */
    stroke-width: 1;
    animation: animateLetter 1s linear forwards;
    animation-delay: .5s;
  }

svg #_I {
    /* stroke: #e0e1e0; */
    stroke-width: 1;
    animation: animateLetter 1s linear forwards;
    animation-delay: .5s;
  }

svg #_N {
    /* stroke: #e0e1e0; */
    stroke-width: 1;
    animation: animateLetter 1s linear forwards;
    animation-delay: .5s;
  }

svg #_E {
    /* stroke: #e0e1e0; */
    stroke-width: 1;
    animation: animateLetter 1s linear forwards;
    animation-delay: .5s;
  }

svg #_S2 {
    /* stroke: #e0e1e0; */
    stroke-width: 1;
    animation: animateLetter 1s linear forwards;
    animation-delay: .5s;
  }

svg #_S3 {
    /* stroke: #e0e1e0; */
    stroke-width: 1;
    animation: animateLetter 1s linear forwards;
    animation-delay: .5s;
  }
  
  @keyframes animateLetter {
    0%{
        stroke: #000;
      stroke-dasharray: 0 50%;
    }
    100%{
        stroke: #e0e1e0;
        stroke-dasharray: 50% 0;
    }
  }
