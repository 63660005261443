svg #_BM {
    stroke-width: 1;
    opacity: 0;
    stroke-dasharray: 0;
    animation: animateLetterMobile 1s linear forwards;
    animation-delay: .5s;
  }

svg #_UM {
    stroke-width: 1;
    opacity: 0;
    stroke-dasharray: 0;
    animation: animateLetterMobile 1s linear forwards;
    animation-delay: .5s;
  }

svg #_SM {
    stroke-width: 1;
    opacity: 0;
    animation: animateLetterMobile 1s linear forwards;
    animation-delay: .5s;
  }

svg #_IM {
    stroke-width: 1;
    opacity: 0;
    animation: animateLetterMobile 1s linear forwards;
    animation-delay: .5s;
  }

svg #_NM {
    stroke-width: 1;
    opacity: 0;
    animation: animateLetterMobile 1s linear forwards;
    animation-delay: .5s;
  }

svg #_EM {
    stroke-width: 1;
    opacity: 0;
    animation: animateLetterMobile 1s linear forwards;
    animation-delay: .5s;
  }

svg #_S2M {
    stroke-width: 1;
    opacity: 0;
    animation: animateLetterMobile 1s linear forwards;
    animation-delay: .5s;
  }

svg #_S3M {
    stroke-width: 1;
    opacity: 0;
    animation: animateLetterMobile 1s linear forwards;
    animation-delay: .5s;
  }
  
  @keyframes animateLetterMobile {
    0%{
        stroke: #000;
        opacity: 0;
      stroke-dasharray: 0 50%;
    }
    100%{
        opacity: 1;
        stroke: #e0e1e0;
        stroke-dasharray: 50% 0;
    }
  }
