@media only screen and (min-width: 768px) {
  #mainLogo{
    width: 100vw;
    height: 100vh;
    background-color: black;
  }
}

@media only screen and (max-width: 767px) {
  #mainLogo{
      width: 106vw;
      height: 100vh;
      background-color: black;
  }
}


html {
    scroll-behavior: smooth;
  }

  .subTitle{
    opacity: 0;
    margin-top: 10px;
    animation: animateSubTitle .5s linear forwards;
    animation-delay: 1.5s;
  }

  @keyframes animateSubTitle {
    0%{
        opacity: 0;
      transform: translateY(20px);
    }
    100%{
        opacity: 1;
      transform: translateY(0px);
    }
  }

  .imgFullWidth{
    width: 100%;
  }

  @media only screen and (max-width: 767px) {
    .maxWidth{
      width: 100vw;
      margin-right: 20vw;
    }
  }


