.justifyText{
    text-align: justify;
}

@media only screen and (min-width: 768px) {
    .parallaxVarejo{
        background-image: url('/src/media/background/varejo.jpg');
        min-height: 60vh;
        background-attachment: fixed;
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
        border-bottom: 60vh solid transparent;
        border-left: 20vh solid white;
    }
    .parallaxAtacadista{
        background-image: url('/src/media/background/atacadista.jpg');
        min-height: 60vh;
        align-self: center;
        background-attachment: fixed;
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;
        border-bottom: 60vh solid transparent;
        border-right: 20vh solid white;
    }
    .parallaxEmpreendimentos{
        background-image: url('/src/media/background/empreendimentos.jpg');
        min-height: 60vh;
        background-attachment: fixed;
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
        border-bottom: 60vh solid transparent;
        border-left: 20vh solid white;
    }
  }



@media only screen and (max-width: 767px) {
    .parallaxVarejo{
        background-image: url('/src/media/background/varejistaMobile.jpg');
        height: 80vh;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .parallaxAtacadista{
        background-image: url('/src/media/background/atacadistaMobile.jpg');
        height: 80vh;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .parallaxEmpreendimentos{
        background-image: url('/src/media/background/empreendimentosMobile.jpg');
        min-height: 80vh;
        /* background-attachment: fixed; */
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100vw;
    }
    .positionAbsoluteMobile{
        position: absolute;
    }
    .maginLeft{
        margin-left: 10px;
    }
    .textBorder{
        text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff;
    }
  }

