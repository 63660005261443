svg #big_gear {
    animation: rotate 5s linear infinite;
    transform-box: fill-box;
    transform-origin: center;
  }
  
  @keyframes rotate {
    50%,100%{
       transform: rotate(360deg)
    }
  }
svg #small_gear {
    animation: rotateSmall 5s linear infinite;
    transform-box: fill-box;
    transform-origin: center;
  }
  
  @keyframes rotateSmall {
    50%,100%{
       transform: rotate(-360deg)
    }
  }

  svg #handLeft1 {
    animation: HandshakeLeft1 5s linear infinite;
    transform-box: fill-box;
    transform-origin: center;
    transition: 0.5s ease-in-out;
  }
  
  @keyframes HandshakeLeft1 {
    0%{
      d:path("M89.85 335.034c0,0 44.468,37.242 92.484,20.991 2.979,-1.007 6.236,-0.788 9.015,0.678l13.804 7.294c5.794,3.057 12.971,1.05 16.333,-4.573 1.918,-3.211 3.554,-6.873 3.879,-10.385l-27.012 -10.935 1.993 -3.311c0,0 -13.188,-1.117 -16.641,8.333 0,0 -25.118,10.45 -67.483,-36.147 0,0 -19.223,16.346 -26.372,28.055z")
    }
    12.5%{
      d: path("M85.412 335.724c0,0 48.013,44.693 97.206,32.46 3.052,-0.757 6.279,-0.27 8.928,1.42l13.155 8.408c5.522,3.525 12.84,2.117 16.655,-3.21 2.176,-3.041 4.109,-6.556 4.722,-10.03l-26.017 -13.126 2.259 -3.135c0,0 -13.05,-2.202 -17.271,6.932 0,0 -32.664,4.433 -71.04,-45.502 0,0 -20.506,14.705 -28.597,25.783z");
    }
    25%{
      d:path("M89.85 335.034c0,0 44.468,37.242 92.484,20.991 2.979,-1.007 6.236,-0.788 9.015,0.678l13.804 7.294c5.794,3.057 12.971,1.05 16.333,-4.573 1.918,-3.211 3.554,-6.873 3.879,-10.385l-27.012 -10.935 1.993 -3.311c0,0 -13.188,-1.117 -16.641,8.333 0,0 -25.118,10.45 -67.483,-36.147 0,0 -19.223,16.346 -26.372,28.055z")
    }
    37.5%{
      d: path("M85.412 335.724c0,0 48.013,44.693 97.206,32.46 3.052,-0.757 6.279,-0.27 8.928,1.42l13.155 8.408c5.522,3.525 12.84,2.117 16.655,-3.21 2.176,-3.041 4.109,-6.556 4.722,-10.03l-26.017 -13.126 2.259 -3.135c0,0 -13.05,-2.202 -17.271,6.932 0,0 -32.664,4.433 -71.04,-45.502 0,0 -20.506,14.705 -28.597,25.783z");
    }
    50%,100%{
      d:path("M89.85 335.034c0,0 44.468,37.242 92.484,20.991 2.979,-1.007 6.236,-0.788 9.015,0.678l13.804 7.294c5.794,3.057 12.971,1.05 16.333,-4.573 1.918,-3.211 3.554,-6.873 3.879,-10.385l-27.012 -10.935 1.993 -3.311c0,0 -13.188,-1.117 -16.641,8.333 0,0 -25.118,10.45 -67.483,-36.147 0,0 -19.223,16.346 -26.372,28.055z")
    }

  }

  svg #handRight1 {
    animation: HandshakeRight1 5s linear infinite;
    transform-box: fill-box;
    transform-origin: center;
    transition: 0.5s ease-in-out;
  }
  
  @keyframes HandshakeRight1 {
    0%{
      d:path("M218.996 339.857c0,0 -4.044,-10.64 -8.764,-9.665 -4.722,0.972 -9.886,4.601 -9.886,4.601l-7.586 10.259c0,0 21.294,8.387 28.78,3.58 0,0 21.751,3.757 49.214,-0.749 -3.976,-8.393 -6.345,-16.798 -7.736,-23.319 -13.91,7.873 -28.894,13.754 -44.022,15.293z")
    }
    12.5%{
      d: path("M227.532 356.559c0,0 -8.13,-13.465 -12.684,-11.889 -4.558,1.573 -11.827,3.83 -11.827,3.83l-2.96 3.146c0,0 21.917,16.615 28.721,10.882 0,0 25.501,0.711 52.154,-7.297 -5.025,-7.81 -8.457,-15.84 -10.677,-22.127 -12.779,9.601 -27.923,19.979 -42.727,23.455z");
    }
    25%{
      d: path("M218.996 339.857c0,0 -4.044,-10.64 -8.764,-9.665 -4.722,0.972 -9.886,4.601 -9.886,4.601l-7.586 10.259c0,0 21.294,8.387 28.78,3.58 0,0 21.751,3.757 49.214,-0.749 -3.976,-8.393 -6.345,-16.798 -7.736,-23.319 -13.91,7.873 -28.894,13.754 -44.022,15.293z");
    }
    37.5%{
      d: path("M227.532 356.559c0,0 -8.13,-13.465 -12.684,-11.889 -4.558,1.573 -11.827,3.83 -11.827,3.83l-2.96 3.146c0,0 21.917,16.615 28.721,10.882 0,0 25.501,0.711 52.154,-7.297 -5.025,-7.81 -8.457,-15.84 -10.677,-22.127 -12.779,9.601 -27.923,19.979 -42.727,23.455z");
    }
    50%,100%{
      d:path("M218.996 339.857c0,0 -4.044,-10.64 -8.764,-9.665 -4.722,0.972 -9.886,4.601 -9.886,4.601l-7.586 10.259c0,0 21.294,8.387 28.78,3.58 0,0 21.751,3.757 49.214,-0.749 -3.976,-8.393 -6.345,-16.798 -7.736,-23.319 -13.91,7.873 -28.894,13.754 -44.022,15.293z")
    }
  }

  svg #handRight2 {
    animation: HandshakeRight2 5s linear infinite;
    transform-box: fill-box;
    transform-origin: center;
    transition: 0.5s ease-in-out;
  }
  
  @keyframes HandshakeRight2 {
    0%{
      d:path("M334.201 262.676c0,0 -31.138,39.221 -71.183,61.888 1.391,6.521 3.76,14.926 7.736,23.319 20.773,-3.41 44.815,-11.544 65.197,-29.619l-1.75 -55.588z")
    }
    12.5%{
      d: path("M334.201 262.676c0,0 -27.153,42.789 -63.942,70.428 2.22,6.287 5.652,14.317 10.677,22.127 20.16,-6.058 42.953,-17.223 60.836,-37.775l-7.571 -54.78z");
    }
    25%{
      d:path("M334.201 262.676c0,0 -31.138,39.221 -71.183,61.888 1.391,6.521 3.76,14.926 7.736,23.319 20.773,-3.41 44.815,-11.544 65.197,-29.619l-1.75 -55.588z")
    }
    37.5%{
      d: path("M334.201 262.676c0,0 -27.153,42.789 -63.942,70.428 2.22,6.287 5.652,14.317 10.677,22.127 20.16,-6.058 42.953,-17.223 60.836,-37.775l-7.571 -54.78z");
    }
    50%,100%{
      d:path("M334.201 262.676c0,0 -31.138,39.221 -71.183,61.888 1.391,6.521 3.76,14.926 7.736,23.319 20.773,-3.41 44.815,-11.544 65.197,-29.619l-1.75 -55.588z")
    }
  }