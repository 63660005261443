#mainDivNavBar{
    width: 100vw;
    height: 70px;
    opacity: 0;
    background-color: black;
    z-index: 10;
    align-items: center;
    bottom: 0;
    transition: top 0.3s;
    animation: animateSubTitle .5s linear forwards;
    animation-delay: 1.5s;
}

  @keyframes animateSubTitle {
    0%{
        opacity: 0;
      transform: translateY(20px);
    }
    100%{
        opacity: 1;
      transform: translateY(0px);
    }
  }


.backgoundBlack{
    background-color: black;
}

.positionAbsolute{
    position: absolute;
    transition: .5 ease-in-out;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 20px;
    transition: .5 ease-in-out;
}
.itensList{
    flex-direction: row;
}
.colItem{
    margin-left: 3px;
    margin-right: 3px;
}

.btnItem{
    width: 100%;
    height: 100%;
    border-width: 1px;
    border-style: solid;
    transition: background-color .5 ease-in-out;
    border-color: white;
    border-radius: 5px;
    border-width: 1px;
}

.btnItemScroll{
    transition: background-color .5 ease-in-out;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: black;
}

.currentItemScroll{
    transition: background-color .5 ease-in-out;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    background-color: black;
}

.navbarBrand:hover{
  cursor: pointer;
}
